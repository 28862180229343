import './Confirm.module.less';

import { exclamationTriangleIcon } from '@neutrino/fa-icons/solid';
import { Modal } from 'antd';
import NuIcon from 'components/NuIcon';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

const Confirm = forwardRef(({ content, styleName, ...props }, ref) => {
  const [visible, setVisible] = useState(false);
  const [pending, setPending] = useState(false);
  const [options, setOptions] = useState({});
  const styleNames = (styleName || '').split(' ');
  styleNames.push('confirm');

  useImperativeHandle(ref, () => ({
    show(opt) {
      setOptions(opt);
      setVisible(true);
    },
  }));

  const footer = useMemo(() => {
    return (
      <div className="flex-row main-center">
        <button
          className="primary"
          disabled={pending}
          onClick={() => {
            const p = options.onOk();

            if (typeof p === 'object' && typeof p.then === 'function') {
              // if onOk callback is a promise
              setPending(true);
              p.then(() => {
                setVisible(false);
              }).finally(() => {
                setPending(false);
              });
            } else {
              setVisible(false);
            }
          }}
        >
          {props.okText || 'OK'}
        </button>
        <button
          className="secondary"
          onClick={() => {
            options?.onCancel();
            setVisible(false);
          }}
        >
          {props.cancelText || 'Cancel'}
        </button>
      </div>
    );
  }, [options, pending, props.cancelText, props.okText]);

  return (
    <Modal
      visible={visible}
      centered
      maskClosable={false}
      styleName={styleNames.join(' ')}
      footer={footer}
      closable={false}
      maskStyle={{
        background: 'rgba(var(--nu-theme-color-background-level3),.8)',
      }}
      width="auto"
      {...props}
    >
      <div className="flex-row cross-center">
        <NuIcon
          icon={exclamationTriangleIcon}
          style={{ color: 'rgb(var(--nu-theme-color-icon-orange))' }}
          data-testid="icon"
        />
        <div style={{ marginLeft: '1em' }} data-testid="content">
          {content}
        </div>
      </div>
    </Modal>
  );
});
Confirm.displayName = 'Confirm';
Confirm.propTypes = {
  styleName: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  content: PropTypes.node,
};

export default Confirm;
