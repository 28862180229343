import ProvisionWizard from 'pages/Express/ProvisionWizard';
import ActionButton from 'pages/Express/shared/ActionButton';
import ProvisionPrompt from 'pages/Express/shared/ProvisionPrompt';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { axios, fetchWrap } from 'utils/fetch';
import storage from 'utils/storage';

import HeroImage from './HeroImage';
import ActivateTrial from './ActivateTrial';

const REGIONS_URL = `/v1/setting/fabric/edges/regions`;

const Licensed = ({ data, onboard, ...props }) => {
  const license = storage.get('license');
  const [needActivation, setNeedActivation] = useState(
    !!license?.ap_promo_sn && license?.license_type === 'default_license'
  );
  const [showWizard, setShowWizard] = useState(false);
  const [siteRegions, setSiteRegions] = useState([]);
  const [logRegions, setLogRegions] = useState([]);
  const [emsRegions, setEmsRegions] = useState([]);

  const checkPromotionalCode = useCallback(code => {
    return fetchWrap({
      url: `/v1/setting/permission/tenants/ap_promotion_code`,
      method: 'post',
      data: {
        code,
      },
    }).then(() => {
      setNeedActivation(false);
      return Promise.resolve();
    });
  });

  const getSiteRegions = useCallback(cancelToken => {
    fetchWrap({
      url: `${REGIONS_URL}/security`,
      cancelToken,
    })
      .then(res => {
        setSiteRegions(res?.data?.data || []);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log({ err });
          // setLoading(false);
        }
      });
  }, []);

  const getLogRegions = useCallback(cancelToken => {
    fetchWrap({
      url: `${REGIONS_URL}/analytics`,
      cancelToken,
    })
      .then(res => {
        setLogRegions(res?.data?.data || []);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log({ err });
          // setLoading(false);
        }
      });
  }, []);

  const getEmsRegions = useCallback(cancelToken => {
    fetchWrap({
      url: `${REGIONS_URL}/ems`,
      cancelToken,
    })
      .then(res => {
        setEmsRegions(res?.data?.data || []);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log({ err });
          // setLoading(false);
        }
      });
  }, []);

  //  fetch all available site locations
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getSiteRegions(source.token);

    return () => source.cancel();
  }, [getSiteRegions]);

  // fetch all available locations for Analytics
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getLogRegions(source.token);

    return () => source.cancel();
  }, [getLogRegions]);

  // fetch all available locations for EMS
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getEmsRegions(source.token);

    return () => source.cancel();
  }, [getEmsRegions]);

  const prompt = data?.prompt || {};

  const licenseType = useMemo(() => {
    const type = license?.license_type?.replace('_license', '')?.replace('default', 'standard'); // show "standard" for default license type since 'default' doesn't make sense to customers
    return `${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  }, []);

  const entitlementDetail = useMemo(() => {
    let text = `FortiSASE ${licenseType}`;
    const remoteUserCount = prompt?.details?.contents?.[1]?.value || 0;
    if (remoteUserCount > 0) {
      text += ` for ${remoteUserCount > 10000 ? '10000+' : remoteUserCount} users`;
    }

    return text;
  }, []);

  const actionButtons = useMemo(() => {
    return (
      <ActionButton type="primary" onClick={() => setShowWizard(true)}>
        Start Onboarding
      </ActionButton>
    );
  }, []);

  return (
    <>
      {needActivation ? (
        <ActivateTrial checkPromotionalCode={checkPromotionalCode} />
      ) : showWizard ? (
        <ProvisionWizard
          data={data}
          onboard={onboard}
          siteRegions={siteRegions}
          logRegions={logRegions}
          emsRegions={emsRegions}
        />
      ) : (
        <ProvisionPrompt
          heroImage={<HeroImage title={prompt?.message} />}
          {...prompt}
          details={null}
          message={null}
          description={
            <>
              <div>
                <div style={{ fontSize: 14, fontWeight: 700 }}>Entitlements</div>
                <div style={{ fontSize: 14 }}>{entitlementDetail}</div>
              </div>
              <div style={{ borderBottom: '1px solid #d9d9d9', margin: '1em 0' }} />
              <div style={{ fontSize: 14 }}>
                <p>
                  You will need to select data centers close to your end-users during provisioning.
                </p>
                <p>
                  Provisioning generally takes <strong>around 10 minutes</strong> but FortiSASE
                  saves your provision progress if you are interrupted.
                </p>
              </div>
            </>
          }
          {...props}
          actions={actionButtons}
          data-testid="licensed-prompt"
        />
      )}
    </>
  );
};

Licensed.propTypes = {
  data: PropTypes.object.isRequired,
  onboard: PropTypes.function,
};

export default Licensed;
