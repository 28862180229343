import './NotificationContent.module.less';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
  ToolTwoTone,
} from '@ant-design/icons';
import { Button, ConfigProvider, List, Tabs, Typography } from 'antd';
import clsx from 'clsx';
import SvgIcon from 'components/SvgIcon';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  clearNotifications,
  readAllNotification,
  readNotification,
} from 'redux/GlobalSetting/globalSetting.actions';
import formatNotification from 'utils/notificationFormat';
import { renderMaintTime } from 'utils/util';
const { TabPane } = Tabs;
const { Paragraph } = Typography;

const SYSTEM = 'system';
const NOTIFICATION = 'notification';

const mapStateToProps = state => ({
  notifications: state.globalSetting.notifications,
  sysInfo: state.globalSetting.sysInfo,
});

const mapDispatchToProps = dispatch => ({
  clearNotifications: () => dispatch(clearNotifications()),
  readAllNotification: () => dispatch(readAllNotification()),
  readNotification: notifiIndex => dispatch(readNotification(notifiIndex)),
});

class NotificationContent extends Component {
  renderLink(notification) {
    const { linkUrl, title } = formatNotification(notification);
    return <Link to={linkUrl}>{title}</Link>;
  }

  renderStatusIcon(state_label) {
    switch (state_label) {
      case 'running':
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case 'failed':
        return <CloseCircleTwoTone twoToneColor="#f5222d" />;
      default:
        return <InfoCircleTwoTone twoToneColor="#1890ff" />;
    }
  }

  renderDescriptin(notification) {
    const { name, state, dateStr } = formatNotification(notification);
    return `${name} ${state} at ${dateStr}`;
  }

  handleItemClick(item, index) {
    !item.read && this.props.readNotification(index);
  }

  renderSysMsg = description => {
    return (
      <ConfigProvider locale={{ Text: { expand: 'show more' } }}>
        <Paragraph ellipsis={{ rows: 2, expandable: true }}>{description}</Paragraph>
      </ConfigProvider>
    );
  };

  render() {
    const { notifications, sysInfo } = this.props;
    const isEmpty = notifications.length === 0;
    const unreadLength = notifications.filter(item => !item.read).length;

    return (
      <div styleName="notifi-content">
        {this.props.visible && (
          <Tabs defaultActiveKey={sysInfo.length ? SYSTEM : NOTIFICATION}>
            <TabPane
              tab={<span>Notification {unreadLength ? `(${unreadLength})` : null}</span>}
              key={NOTIFICATION}
            >
              <div styleName="notifi-items">
                <List
                  size="small"
                  itemLayout="horizontal"
                  dataSource={notifications}
                  style={{ height: '100%' }}
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: 'center', height: '100%' }}>
                        <SvgIcon type="notification" styleName="empty-icon" />
                        <p>No New Notification</p>
                      </div>
                    ),
                  }}
                  renderItem={(item, index) => (
                    <List.Item
                      key={index}
                      onClick={() => this.handleItemClick(item, index)}
                      styleName={clsx({ read: item.read })}
                    >
                      <List.Item.Meta
                        avatar={this.renderStatusIcon(item.data.state_label)}
                        title={this.renderLink(item)}
                        description={this.renderDescriptin(item)}
                      />
                    </List.Item>
                  )}
                />
              </div>
              <div>
                <Button
                  size="small"
                  disabled={isEmpty}
                  onClick={this.props.clearNotifications}
                  styleName="action-btn"
                >
                  Clear all
                </Button>
                <Button
                  size="small"
                  disabled={unreadLength === 0}
                  onClick={this.props.readAllNotification}
                  styleName="action-btn"
                >
                  Read all
                </Button>
              </div>
            </TabPane>

            <TabPane
              tab={<span>System {sysInfo.length ? `(${sysInfo.length})` : null}</span>}
              key={SYSTEM}
            >
              <div styleName="notifi-items sys" style={{ height: 260 }}>
                <List
                  size="small"
                  itemLayout="horizontal"
                  dataSource={sysInfo}
                  style={{ height: '100%' }}
                  locale={{
                    emptyText: (
                      <div style={{ textAlign: 'center', height: '100%' }}>
                        <SvgIcon type="notification" styleName="empty-icon" />
                        <p>No System Notification</p>
                      </div>
                    ),
                  }}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        avatar={<ToolTwoTone twoToneColor="#faad14" />}
                        title={item.title}
                        description={
                          <div>
                            {renderMaintTime(item)}
                            {this.renderSysMsg(item.description)}
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </TabPane>
          </Tabs>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContent);
