import './ProvisionPrompt.module.less';

import {
  checkCircleIcon,
  exclamationTriangleIcon,
  infoCircleIcon,
  timesCircleIcon,
} from '@neutrino/fa-icons/solid';
import { Col, Divider, Row, Typography } from 'antd';
import NuIcon from 'components/NuIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const ProvisionPrompt = ({
  actions,
  children,
  className = '',
  description = '',
  details,
  code = '',
  heroImage,
  message = '',
  severity = 'info',
  style = {},
  iconStyle = {},
  ...options
}) => {
  const containerStyle = { ...style };

  // set icon props based on type
  const iconProps = useMemo(() => {
    const defaultIconStyle = {
      width: '180px',
      height: '180px',
    };
    switch (severity) {
      case 'warning':
        return {
          icon: exclamationTriangleIcon,
          style: {
            ...defaultIconStyle,
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-informative-severity-medium-base))',
          },
          ['data-testid']: 'warning-icon',
        };
      case 'error':
        return {
          icon: timesCircleIcon,
          style: {
            ...defaultIconStyle,
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-icon-red))',
          },
          ['data-testid']: 'error-icon',
        };
      case 'success':
        return {
          icon: checkCircleIcon,
          style: {
            ...defaultIconStyle,
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-icon-green))',
          },
          ['data-testid']: 'success-icon',
        };
      case '':
        return null;
      default:
        return {
          icon: infoCircleIcon,
          style: {
            ...defaultIconStyle,
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-informative-severity-low-base))',
          },
          ['data-testid']: 'info-icon',
        };
    }
  }, [iconStyle, severity]);

  return (
    <div
      data-testid="provision-prompt"
      styleName="provision-prompt"
      className="flex-col"
      {...options}
    >
      <div styleName="hero-img" className="flex-item flex-col main-center cross-center">
        {heroImage || (iconProps && <NuIcon {...iconProps} />)}
      </div>

      <div
        styleName="content-block"
        className={`flex-col main-center cross-center ${className}`}
        style={containerStyle}
      >
        <div className="flex-col">
          {(code || message) && (
            <div styleName="title" className="flex-row main-center cross-center">
              {(code || message) && (
                <h3>
                  {code && <span data-testid="error-code">Error {code}</span>}
                  {message && (
                    <>
                      {code && message && ': '}
                      <span data-testid="message">{message}</span>
                    </>
                  )}
                </h3>
              )}
            </div>
          )}

          {details && (
            <div style={{ fontSize: 14 }}>
              <div style={{ fontWeight: 700 }}>{details.title}</div>
              <Row>
                {details.contents.map(({ label, value }, index) => (
                  <Col key={index} span={12}>
                    <div>
                      <span>{label}</span>:&nbsp; <span>{value}</span>
                    </div>
                  </Col>
                ))}
              </Row>
              <Divider />
            </div>
          )}

          <div styleName="content" className="flex-row cross-start">
            {description && <div data-testid="description">{description}</div>}
          </div>

          {children}

          {actions && (
            <div className="flex-row main-center cross-center" styleName="actions">
              {actions}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProvisionPrompt.propTypes = {
  actions: PropTypes.element,
  children: PropTypes.node,
  code: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.object,
  heroImage: PropTypes.element,
  message: PropTypes.string,
  severity: PropTypes.string,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
};

export default ProvisionPrompt;
