import './style.module.less';

import { Modal } from 'antd';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { axios, fetchWrap } from 'utils/fetch';
import useInterval from 'utils/hooks/useInterval';

import ActionButton from '../shared/ActionButton';
import MaintenanceBoard from './MaintenanceBoard';
import Setup from './Setup';
export const REGIONS_URL = `/v1/setting/fabric/edges/regions`;
const REFRESH_INTERVAL = 60000;

const ProvisionWizard = ({ data, onboard, enterSite, ...props }) => {
  const [siteRegions, setSiteRegions] = useState(props?.siteRegions || []);
  const [logRegions, setLogRegions] = useState(props?.logRegions || []);
  const [emsRegions, setEmsRegions] = useState(props?.emsRegions || []);
  const [pending, setPending] = useState(false);
  const formRef = useRef();
  const wizardRef = useRef();

  const actions = useMemo(() => data?.actions || [], [data?.actions]);
  const existedSites = data?.security?.federated_sites || [];

  const maintenanceRegions = useMemo(() => siteRegions.filter(({ status }) => status === 'down'), [
    siteRegions,
  ]);

  const getSiteRegions = useCallback(cancelToken => {
    fetchWrap({
      url: `${REGIONS_URL}/security`,
      cancelToken,
    })
      .then(res => {
        setSiteRegions(res?.data?.data || []);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log({ err });
          // setLoading(false);
        }
      });
  }, []);

  const getLogRegions = useCallback(cancelToken => {
    fetchWrap({
      url: `${REGIONS_URL}/analytics`,
      cancelToken,
    })
      .then(res => {
        setLogRegions(res?.data?.data || []);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log({ err });
          // setLoading(false);
        }
      });
  }, []);

  const getEmsRegions = useCallback(cancelToken => {
    fetchWrap({
      url: `${REGIONS_URL}/ems`,
      cancelToken,
    })
      .then(res => {
        setEmsRegions(res?.data?.data || []);
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log({ err });
          // setLoading(false);
        }
      });
  }, []);

  //  fetch all available site locations
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getSiteRegions(source.token);

    return () => source.cancel();
  }, [getSiteRegions]);

  // fetch all available locations for Analytics
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getLogRegions(source.token);

    return () => source.cancel();
  }, [getLogRegions]);

  // fetch all available locations for EMS
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    getEmsRegions(source.token);

    return () => source.cancel();
  }, [getEmsRegions]);

  // polling security site & log regions if there's any site under maintenance
  useInterval(() => {
    siteRegions.some(({ status }) => status === 'down') && getSiteRegions();
    logRegions.some(({ status }) => status === 'down') && getLogRegions();
    emsRegions.some(({ status }) => status === 'down') && getEmsRegions();
  }, REFRESH_INTERVAL);

  const onboardHandler = useCallback(
    config => {
      formRef.current.validateFields().then(values => {
        setPending(true);
        const requestOptions = {
          ...config,
          data: {
            site_regions: values.site_regions.map(item => ({ name: item })),
            log_region_name: values.log_region_name,
            ems_location: values.ems_location && JSON.parse(values.ems_location),
          },
        };

        const selectedLogRegion = logRegions.find(({ name }) => name === values.log_region_name);
        const selectedSiteRegions = values.site_regions.map(regionName =>
          siteRegions.find(({ name }) => regionName === name)
        );
        // get unique regions so there won't be duplicated prompts
        const uniqSelectedRegions = uniqBy([...selectedSiteRegions, selectedLogRegion], 'name');
        // reduce the selected site & log regions with a  "ack_reguired" flag to a promise chain,
        // and submit the onboard request only when all the promises are resolved
        uniqSelectedRegions
          .filter(item => !!item?.ack_required)
          .reduce((p, next) => {
            return p.then(() => {
              return new Promise((resolve, reject) => {
                const modal = Modal.confirm({
                  title: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      Acknowledgement required
                    </div>
                  ),
                  content: <div style={{ marginTop: 16 }}>{next?.ack_message || next.name}</div>,
                  icon: null,
                  className: 'ack_prompt',
                  autoFocusButton: null,
                  width: 560,
                  getContainer: () => wizardRef.current,
                  okText: 'I accept',
                  onOk: () => resolve(),

                  cancelText: 'I decline',
                  onCancel: () => {
                    reject();
                    modal.destroy();
                  },
                  centered: true,
                });
              });
            });
          }, Promise.resolve())
          .then(() => {
            return onboard(requestOptions).catch(err => {
              getSiteRegions();
              getLogRegions();
            });
          })
          .finally(() => setPending(false));
      });
    },
    [getLogRegions, getSiteRegions, onboard, logRegions, siteRegions]
  );

  const clickHandler = useCallback(
    ({ key, method, href }) => {
      switch (key) {
        case 'onboard':
          onboardHandler({ method, url: href });
          break;
        case 'enter':
          enterSite();
          break;
        default:
      }
    },
    [enterSite, onboardHandler]
  );

  const actionButtons = useMemo(() => {
    return actions.map(meta => {
      return (
        <ActionButton
          type={meta.type}
          key={meta.key}
          disabled={pending}
          onClick={() => clickHandler(meta)}
          styleName="wizard-buttons"
        >
          {meta.label}
        </ActionButton>
      );
    });
  }, [actions, clickHandler, pending]);

  return (
    <div styleName="wizard" className="flex-row" ref={wizardRef}>
      <div className="flex-item">
        {maintenanceRegions.length ? <MaintenanceBoard data={maintenanceRegions} /> : null}
        <Setup
          ref={formRef}
          siteRegions={siteRegions}
          logRegions={logRegions}
          emsRegions={emsRegions}
          existedSites={existedSites}
          style={{ marginTop: '1em', padding: '20px 24px' }}
          disabled={pending}
        />

        <div className="flex-row main-center" styleName="actions">
          {actionButtons}
        </div>
      </div>
    </div>
  );
};

ProvisionWizard.propTypes = {
  data: PropTypes.object.isRequired,
  onboard: PropTypes.func.isRequired,
  enterSite: PropTypes.func,
  siteRegions: PropTypes.array,
  logRegions: PropTypes.array,
  emsRegions: PropTypes.array,
};

export default ProvisionWizard;
