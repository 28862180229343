import './style.module.less';

import { CaretRightOutlined, WarningTwoTone } from '@ant-design/icons';
import { infoCircleIcon } from '@neutrino/fa-icons/solid';
import { Checkbox, Col, Collapse, Divider, Form, Radio, Row, Tag, Tooltip } from 'antd';
import NuIcon from 'components/NuIcon/NuIcon';
import numToWords from 'num-words';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import storage from 'utils/storage';

const Setup = forwardRef(
  (
    { siteRegions = [], logRegions = [], emsRegions = [], existedSites = [], disabled, ...props },
    ref
  ) => {
    const [form] = Form.useForm();
    const license = storage.get('license');
    const siteSeats = license?.security_entitlement?.site?.seat || 0;
    const addonSeats = license?.security_entitlement?.site?.addon_seats || 0;
    const totalSeats = siteSeats + addonSeats;
    const hasExistingSites = !!existedSites.length; // if true, no need to show selection for log, ems locations as they are already provisioned
    const preselectedRegions = useMemo(() => existedSites.map(({ region_name }) => region_name), [
      existedSites,
    ]);

    // for AP promotional tenant
    // 1. hide alternative data center options
    // 2. bind FAZ selection to FOS selection
    // 3. hide the EMS section and randomly pick one EMS region (pick the 1st from the list for simplicity)
    const isApPromotional = !!license?.ap_promo_sn;

    const checkFazRegion = license?.analytics_entitlement?.region_restriction;
    const recommendedSiteRegions = useMemo(() => {
      return siteRegions.filter(({ tier_number }) => tier_number !== 1);
    }, [siteRegions]);
    const alternativeSiteRegions = useMemo(() => {
      return siteRegions.filter(({ tier_number }) => tier_number === 1);
    }, [siteRegions]);

    useEffect(() => {
      // pre-select locations that already have sites created at
      preselectedRegions.length &&
        form.setFieldsValue({
          site_regions: preselectedRegions,
        });
    }, [form, preselectedRegions]);

    // re-validate log region if site regions changed
    const handleValuesChange = useCallback(
      (changedValues, allValues) => {
        if (changedValues.site_regions) {
          if (isApPromotional) {
            form.setFieldValue('log_region_name', changedValues.site_regions[0]); // bind FAZ selection to FOS selection

            // pick 1st EMS region
            if (!allValues.ems_location) {
              const emsRegion = emsRegions[0];
              form.setFieldValue(
                'ems_location',
                JSON.stringify({
                  ems_alias: emsRegion.ems_alias,
                  ems_location: emsRegion.ems_location,
                })
              );
            }
          }

          form.isFieldTouched('log_region_name') && form.validateFields(['log_region_name']);
        }
      },
      [form]
    );

    return (
      <div {...props}>
        <section styleName="section" className="flex-row main-center">
          <span style={{ fontSize: 20, fontWeight: 500, textAlign: 'center' }}>
            <strong>
              FortiSASE offers a global network of Tier 3+ data centers to secure remote user
              traffic and retain associated security logs.
            </strong>
          </span>
        </section>

        <Form
          form={form}
          ref={ref}
          onValuesChange={handleValuesChange}
          style={{ marginTop: '1em' }}
        >
          {totalSeats > 0 && (
            <section styleName="form-section">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  // should update the selected count if site regions change
                  (prevValues.site_regions || []).length !==
                  (currentValues.site_regions || []).length
                }
              >
                {({ getFieldValue }) => (
                  <div styleName="section-title">
                    <h4 data-testid="security-sites-title">
                      Points of Presence ({(getFieldValue('site_regions') || []).length}/
                      {totalSeats})
                      <Tooltip
                        placement="right"
                        title="If your end users are globally distributed, select one data center in each of the US, EMEA and APAC regions and select an additional data center in the region with the most users."
                        color="rgb(255, 255, 255)"
                        overlayInnerStyle={{
                          color: 'rgb(30, 30, 30)',
                          padding: '1em',
                          borderRadius: 0,
                        }}
                      >
                        <NuIcon
                          icon={infoCircleIcon}
                          style={{
                            marginLeft: '.5em',
                            color: 'rgb(var(--nu-theme-color-informative-severity-low-base))',
                          }}
                        />
                      </Tooltip>
                    </h4>
                    <div className="flex-row cross-center">
                      <p style={{ fontWeight: 400, fontSize: 12 }}>
                        {totalSeats > 1
                          ? `Please select up to ${totalSeats} data centers to host PoPs. Remote users are routed to available PoPs that are geographically closest to them.`
                          : `Please select one data center to host the PoP to which remote users will be routed.`}
                      </p>
                    </div>
                  </div>
                )}
              </Form.Item>

              <div styleName="section-content">
                <Form.Item
                  name="site_regions"
                  rules={[
                    {
                      type: 'array',
                      required: true,
                      min: 1,
                      max: totalSeats,
                      message: `Please select up to ${totalSeats} data centers to host PoPs.`,
                    },
                  ]}
                >
                  <Checkbox.Group style={{ width: '100%' }}>
                    <h3 style={{ fontWeight: 300, color: '#656565' }}>Recommended Data Centers</h3>
                    <Row>
                      {recommendedSiteRegions
                        // sort regions alphabetically
                        .sort((a, b) => {
                          switch (true) {
                            case a.alias > b.alias:
                              return 1;
                            case a.alias < b.alias:
                              return -1;
                            default:
                              return 0;
                          }
                        })
                        .map(({ name, alias, status }) => (
                          <Col
                            key={name}
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 6 }}
                          >
                            <Checkbox
                              value={name}
                              disabled={
                                disabled ||
                                (preselectedRegions || []).includes(name) ||
                                status === 'down'
                              }
                            >
                              {alias}
                              &nbsp;
                              {status === 'down' && (
                                <Tooltip title="This data center is scheduled for maintenance.">
                                  <WarningTwoTone twoToneColor="#fadb14" />
                                </Tooltip>
                              )}
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>

                    {!isApPromotional && !!alternativeSiteRegions?.length && (
                      <Collapse
                        ghost
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined
                            style={{ color: '#656565' }}
                            rotate={isActive ? 90 : 0}
                          />
                        )}
                      >
                        <Collapse.Panel
                          header={
                            <h3 style={{ fontWeight: 300, color: '#656565' }}>
                              Alternative Data Centers
                            </h3>
                          }
                          key="1"
                          forceRender
                        >
                          <p style={{ fontWeight: 300, color: '#656565' }}>
                            For consistent performance, Fortinet strongly recommends data centers
                            from the above list. The following data centers are available for users
                            with specific requirements.
                          </p>
                          <Row>
                            {alternativeSiteRegions
                              // sort regions alphabetically
                              .sort((a, b) => {
                                switch (true) {
                                  case a.alias > b.alias:
                                    return 1;
                                  case a.alias < b.alias:
                                    return -1;
                                  default:
                                    return 0;
                                }
                              })
                              .map(({ name, alias, status }) => (
                                <Col
                                  key={name}
                                  xs={{ span: 24 }}
                                  sm={{ span: 12 }}
                                  md={{ span: 8 }}
                                  lg={{ span: 6 }}
                                >
                                  <Checkbox
                                    value={name}
                                    disabled={
                                      disabled ||
                                      (preselectedRegions || []).includes(name) ||
                                      status === 'down'
                                    }
                                  >
                                    {alias}
                                    &nbsp;
                                    {status === 'down' && (
                                      <Tooltip title="This data center is scheduled for maintenance.">
                                        <WarningTwoTone twoToneColor="#fadb14" />
                                      </Tooltip>
                                    )}
                                  </Checkbox>
                                </Col>
                              ))}
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    )}
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </section>
          )}

          {!hasExistingSites && (
            <>
              <Divider />
              <section styleName="form-section">
                <div styleName="section-title">
                  <h4 data-testid="logging-title">Logging</h4>
                  <p style={{ fontWeight: 400, fontSize: 12 }}>
                    Please select one data center to host the logging service. Once provisioned, the
                    data center cannot be changed.
                  </p>
                </div>
                <div styleName="section-content">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      // should re-evaluate validation rules if site changed
                      (prevValues.site_regions || []).toString() !==
                      (currentValues.site_regions || []).toString()
                    }
                  >
                    {({ getFieldValue }) => (
                      <Form.Item
                        name="log_region_name"
                        rules={[
                          {
                            required: true,
                            message:
                              'Please select a storage data center to host the logging service.',
                          },
                          checkFazRegion && {
                            validator: async (rule, value) => {
                              if (!(getFieldValue('site_regions') || []).includes(value)) {
                                throw new Error(
                                  'Please make sure selected logging region is one of your selected PoP regions.'
                                );
                              }
                            },
                          },
                        ]}
                      >
                        <Radio.Group data-test="log-location" style={{ width: '100%' }}>
                          <Row>
                            {logRegions
                              // prevent any of legacy DCs from being selected as a logging region
                              .filter(({ tier_number }) => tier_number !== 1)
                              // sort regions alphabetically
                              .sort((a, b) => {
                                switch (true) {
                                  case a.alias > b.alias:
                                    return 1;
                                  case a.alias < b.alias:
                                    return -1;
                                  default:
                                    return 0;
                                }
                              })
                              .map(({ name, alias, is_gdpr_compliant, status }) => (
                                <Col
                                  key={name}
                                  xs={{ span: 24 }}
                                  sm={{ span: 24 }}
                                  md={{ span: 8 }}
                                  lg={{ span: 6 }}
                                >
                                  <Radio
                                    value={name}
                                    disabled={disabled || status === 'down' || isApPromotional}
                                  >
                                    {alias}&nbsp;
                                    {is_gdpr_compliant && (
                                      <Tooltip
                                        title="compliant with GDPR Data Sovereignty Requirements"
                                        placement="right"
                                      >
                                        <Tag color="blue">GDPR</Tag>
                                      </Tooltip>
                                    )}
                                    &nbsp;
                                    {status === 'down' && (
                                      <Tooltip title="This data center is scheduled for maintenance.">
                                        <WarningTwoTone twoToneColor="#fadb14" />
                                      </Tooltip>
                                    )}
                                  </Radio>
                                </Col>
                              ))}
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    )}
                  </Form.Item>
                </div>
              </section>
            </>
          )}

          {!hasExistingSites && (
            <>
              {!isApPromotional && <Divider />}
              <section styleName="form-section">
                {!isApPromotional && (
                  <div styleName="section-title">
                    <h4 data-testid="ems-title">FortiClient Endpoint Management</h4>
                    <p style={{ fontWeight: 400, fontSize: 12 }}>
                      Endpoints will connect to this location through FortiClient to retrieve
                      endpoint configuration and to validate licenses. Once provisioned, the
                      location cannot be changed.
                    </p>
                  </div>
                )}
                <div styleName="section-content" style={isApPromotional ? { padding: 0 } : null}>
                  <Form.Item noStyle>
                    <Form.Item
                      name="ems_location"
                      rules={[
                        {
                          required: true,
                          message: 'Please select a location for FortiClient Endpoint Management.',
                        },
                      ]}
                      hidden={isApPromotional} // still collect and validate value
                    >
                      <Radio.Group data-test="ems-location" style={{ width: '100%' }}>
                        <Row>
                          {emsRegions
                            // sort regions alphabetically
                            .sort((a, b) => {
                              switch (true) {
                                case a.ems_alias > b.ems_alias:
                                  return 1;
                                case a.ems_alias < b.ems_alias:
                                  return -1;
                                default:
                                  return 0;
                              }
                            })
                            .map(({ ems_name, ems_alias, ems_location }, index) => (
                              <Col
                                key={`${ems_name}${index}`}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 6 }}
                              >
                                <Radio
                                  value={JSON.stringify({ ems_alias, ems_location })}
                                  disabled={disabled}
                                >
                                  {ems_alias}&nbsp;
                                </Radio>
                              </Col>
                            ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Form.Item>
                </div>
              </section>
            </>
          )}
        </Form>
      </div>
    );
  }
);
Setup.displayName = 'Setup';
Setup.propTypes = {
  siteRegions: PropTypes.array.isRequired,
  logRegions: PropTypes.array,
  emsRegions: PropTypes.array,
  existedSites: PropTypes.array,
  disabled: PropTypes.bool,
};

export default Setup;
