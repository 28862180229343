import Prompt from 'components/Prompt';
import * as get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

const Conflict = ({ data, ...props }) => {
  const prompt = get(data, 'prompt', {});
  return <Prompt {...prompt} {...props} data-testid="conflict-prompt" />;
};

Conflict.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Conflict;
