import { Breadcrumb } from 'antd';
import React, { Fragment } from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Link } from 'react-router-dom';
import routes from 'routeConfig';

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <Breadcrumb>
      {breadcrumbs.map(breadcrumb => (
        <Breadcrumb.Item key={breadcrumb.match.url}>
          <BreadcrumbLabel {...breadcrumb} />
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export const BreadcrumbLabel = ({ match, location, breadcrumb }) => {
  const isActive = match.url === location.pathname;
  return <Fragment>{isActive ? breadcrumb : <Link to={match.url}>{breadcrumb}</Link>}</Fragment>;
};

export default withBreadcrumbs(routes)(Breadcrumbs);
