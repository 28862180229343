import './Prompt.module.less';

import { exclamationTriangleIcon, infoCircleIcon, timesCircleIcon } from '@neutrino/fa-icons/solid';
import { Collapse, Typography } from 'antd';
import NuIcon from 'components/NuIcon';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const Prompt = ({
  actions,
  children,
  className = '',
  description = '',
  details,
  code = '',
  message = '',
  severity = 'info',
  style = {},
  showIcon = true,
  ...options
}) => {
  const containerStyle = {
    border: '1px solid rgb(var(--nu-theme-color-border-level2))',
    borderRadius: 0,
    backgroundColor: 'rgb(var(--nu-theme-color-background-level1))',
    maxHeight: 'calc(100vh - 3em - 2px)',
    maxWidth: 800,
    ...style,
  };

  // set icon props based on type
  const iconProps = useMemo(() => {
    const iconStyle = {
      width: 18,
      height: 18,
      marginTop: 'calc((1.5715em - 18px) / 2)',
    };

    switch (severity) {
      case 'warning':
        return {
          icon: exclamationTriangleIcon,
          style: {
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-informative-severity-high-base))',
          },
          ['data-testid']: 'warning-icon',
        };
      case 'error':
        return {
          icon: timesCircleIcon,
          style: {
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-icon-red))',
          },
          ['data-testid']: 'error-icon',
        };
      default:
        return {
          icon: infoCircleIcon,
          style: {
            ...iconStyle,
            color: 'rgb(var(--nu-theme-color-informative-severity-low-base))',
          },
          ['data-testid']: 'info-icon',
        };
    }
  }, [severity]);

  return (
    <div
      styleName="prompt"
      className={`flex-row main-center ${className}`}
      style={containerStyle}
      {...options}
    >
      <div className="flex-col">
        {(code || message) && (
          <div styleName="title" className="flex-row main-center cross-center">
            {(code || message) && (
              <h3>
                {code && <span data-testid="error-code">Error {code}</span>}
                {message && (
                  <>
                    {code && message && ': '}
                    <span data-testid="message">{message}</span>
                  </>
                )}
              </h3>
            )}
          </div>
        )}

        <div styleName="content" className="flex-row cross-start">
          {showIcon && <NuIcon {...iconProps} />}
          {description && (
            <div
              data-testid="description"
              style={{ marginLeft: '1em' }}
              ref={node => (node.innerText = description?.replaceAll('\n-', '\n\u2022 '))}
            />
          )}
        </div>
        {details && (
          <Collapse
            expandIconPosition="right"
            bordered={false}
            style={{ width: 480, margin: 'auto', maxWidth: '100%' }}
            styleName="collapse"
          >
            <Collapse.Panel key="details" header={details.title}>
              <ul data-testid="details">
                {details.contents.map(({ label, value }, index) => {
                  return (
                    <div key={index} styleName="item">
                      <Typography.Text
                        style={{ color: 'rgba(var(--nu-theme-on-color-background),.6)' }}
                      >
                        {label}
                      </Typography.Text>
                      &nbsp; :&nbsp; <Typography.Text>{value}</Typography.Text>
                    </div>
                  );
                })}
              </ul>
            </Collapse.Panel>
          </Collapse>
        )}

        {children}

        {actions && (
          <div
            className="flex-row main-center cross-center"
            style={{ marginTop: '.5em', gap: '.5em', minHeight: 'auto' }}
          >
            {actions}
          </div>
        )}
      </div>
    </div>
  );
};

Prompt.propTypes = {
  actions: PropTypes.element,
  children: PropTypes.node,
  code: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.object,
  message: PropTypes.string,
  severity: PropTypes.string,
  style: PropTypes.object,
  showIcon: PropTypes.bool,
};

export default Prompt;
