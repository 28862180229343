import { cloneIcon } from '@neutrino/fa-icons/solid';
import { forticareIcon } from '@neutrino/ftnt-icons/products';
import Alert from 'components/Alert';
import NuIcon from 'components/NuIcon';
import Slide from 'components/Slide/Slide';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';

import ActionButton from './ActionButton';

const SupportButton = ({ link, ticket, type }) => {
  const slideRef = useRef();

  const openSlide = useCallback(() => {
    slideRef.current.open();
  }, []);

  const copyTicket = useCallback(
    e => {
      e.target.blur();
      e.target.parentNode.blur();
      const text = ticket;

      if (!navigator.clipboard) {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          console.log('Async: Copying to clipboard was successful!');
        },
        err => {
          console.error('Async: Could not copy text: ', err);
        }
      );
    },
    [ticket]
  );

  return (
    <>
      <ActionButton
        key="support"
        data-testid="support-btn"
        className="flex-row cross-center"
        onClick={openSlide}
        type={type}
      >
        <NuIcon icon={forticareIcon} /> <span>FortiCare Support</span>
      </ActionButton>
      <Slide
        ref={slideRef}
        title="FortiCare Support"
        footer={[]}
        cancelText="OK"
        getContainer={'[class*="express"]'}
        style={{ position: 'absolute' }}
        data-testid="forticare-support-slide"
      >
        <Alert message="Please include the following information in your FortiCare support ticket." />

        <pre
          style={{
            margin: '1em .5em',
            padding: '1em',
            borderRadius: 5,
            border: '1px solid rgb(var(--nu-theme-color-border-level1))',
            fontFamily: 'inherit',
          }}
        >
          {ticket}
        </pre>

        <div className="flex-row main-center cross-center" style={{ gap: '.5em' }}>
          <ActionButton type="primary" onClick={() => window.open(link, '_blank')}>
            <NuIcon icon={forticareIcon} /> <span>FortiCare Support</span>
          </ActionButton>

          <ActionButton onClick={copyTicket}>
            <NuIcon
              icon={cloneIcon}
              style={{
                color: 'rgb(var(--nu-theme-color-call-to-action-base))',
              }}
            />
            &nbsp;
            <span>Copy Details</span>
          </ActionButton>
        </div>
      </Slide>
    </>
  );
};

SupportButton.propTypes = {
  ticket: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SupportButton;
