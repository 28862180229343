import { ToolTwoTone } from '@ant-design/icons';
import { Alert, Carousel, Layout } from 'antd';
import NavBar from 'components/NavBar';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setBreakpoint } from 'redux/GlobalSetting/globalSetting.actions';
import { getMaintInfo } from 'redux/GlobalSetting/globalSetting.actions';
import routes from 'routeConfig';
import useInterval from 'utils/hooks/useInterval';
import { getBreakpoint, renderMaintTime } from 'utils/util';

import SideBar from './components/SideBar';
import Header from './core/Header';
import { ROOT_PATH } from './remote-user-portal/shared/constants';
import Routes from './Routes';

const { Content } = Layout;

const REFRESH_INTERVAL = 60000; // 15 seconds (15000 milliseconds)

const AppLayout = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const breakpoint = useSelector(state => state.globalSetting.breakpoint);
  const sysInfo = useSelector(state => state.globalSetting.sysInfo);
  const isAuthenticated = useSelector(state => state.globalSetting.isAuthenticated);

  // set initial breakpoint
  useEffect(() => {
    const breakpoint = getBreakpoint();
    dispatch(setBreakpoint(breakpoint));
  }, [dispatch]);

  const onResize = useCallback(() => {
    const current = getBreakpoint();
    if (current !== breakpoint) {
      dispatch(setBreakpoint(current));
    }
  }, [breakpoint, dispatch]);

  // bind onResize handler to window
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    isAuthenticated &&
      !!sessionStorage.getItem('access_token') &&
      location.pathname !== '/logout' &&
      dispatch(getMaintInfo());
  }, [isAuthenticated]);

  // polling system broadcast
  useInterval(() => {
    isAuthenticated && !!sessionStorage.getItem('access_token') && dispatch(getMaintInfo());
  }, REFRESH_INTERVAL);

  // const showHeader = useMemo(() => [`${ROOT_PATH}/account`].indexOf(pathname) === -1, [pathname]);

  const showSider = useMemo(() => {
    const publicRoutes = routes.filter(route => route.public).map(({ path }) => path);
    return [...publicRoutes, `${ROOT_PATH}/account`, '/'].indexOf(pathname) === -1;
  }, [pathname]);

  const showBreadcrumbs = useMemo(() => {
    const publicRoutes = routes.filter(route => route.public).map(({ path }) => path);
    return [...publicRoutes, `${ROOT_PATH}/account`, '/'].indexOf(pathname) === -1;
  }, [pathname]);

  return (
    <Layout style={{ height: '100%' }}>
      <Header />
      <Layout style={{ height: '100%', marginTop: 41 }}>
        {showSider && <SideBar />}
        <Content className="main-content flex-col flex-item">
          {sysInfo && (
            <div className="system-alert">
              <Carousel autoplay autoplaySpeed={15000}>
                {sysInfo.map(info => (
                  <Alert
                    key={info.id}
                    message={info.title}
                    description={
                      <div style={{ marginBottom: 12 }}>
                        <div>{renderMaintTime(info)}</div>
                        <div>{info.description}</div>
                      </div>
                    }
                    icon={<ToolTwoTone twoToneColor="#faad14"></ToolTwoTone>}
                    banner
                  />
                ))}
              </Carousel>
            </div>
          )}

          {showBreadcrumbs && <NavBar />}

          <Routes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
