import Icon from '@ant-design/icons';
import { ReactComponent as FAZNode } from 'assets/icons/provision/FAZ.svg';
import { ReactComponent as FortiClientNode } from 'assets/icons/provision/FortiClient.svg';
import { ReactComponent as FortiOSNode } from 'assets/icons/provision/FortiOS.svg';
import { ReactComponent as FortiSanboxNode } from 'assets/icons/provision/fortisandbox.svg';
import { ReactComponent as SSONode } from 'assets/icons/provision/SSO.svg';
import { ReactComponent as ZTNANode } from 'assets/icons/provision/ZTNA.svg';
import heroImageHexagon from 'assets/images/hero_img_hexagon.svg';
import heroImageBg from 'assets/images/welcome_hero_bg.svg';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import useInterval from 'utils/hooks/useInterval';

const HeroImage = ({ title }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useInterval(() => {
    setCurrentIndex((currentIndex + 1) % 6);
  }, 5000);

  const nodes = useMemo(() => {
    return [
      {
        key: 'FortiOS',
        icon: { component: FortiOSNode, delta: { x: '- 32px', y: '- 57px' } },
        label: {
          text: 'Cloud-based policy enforcement powered by FortiOS',
          style: {
            width: 210,
            transform: `translate(calc(-50% - 200px), calc(-50% - 70px))`,
          },
        },
      },
      {
        key: 'SSO',
        icon: { component: SSONode, delta: { x: '+ 32px', y: '- 57px' } },
        label: {
          text: 'SSO-integration via SAML for VPN and SWG',
          style: { width: 210, transform: `translate(calc(-50% + 220px), calc(-50% - 70px))` },
        },
      },
      {
        key: 'FortiClient',
        icon: { component: FortiClientNode, delta: { x: '+ 65px', y: '' } },
        label: {
          text: 'Endpoint management powered by EMS and FortiClient',
          style: { width: 210, transform: `translate(calc(-50% + 220px), calc(-50%))` },
        },
      },
      {
        key: 'FortiSanbox',
        icon: {
          component: FortiSanboxNode,
          delta: { x: '+ 32px', y: '+ 57px' },
        },
        label: {
          text: 'Built-in FortiSandbox',
          style: { width: 210, transform: `translate(calc(-50% + 220px), calc(-50% + 65px))` },
        },
      },
      {
        key: 'ZTNA',
        icon: { component: ZTNANode, delta: { x: '- 32px', y: '+ 57px' } },
        label: {
          text: 'Zero-Trust Network Access',
          style: { width: 210, transform: `translate(calc(-50% - 200px), calc(-50% + 65px))` },
        },
      },
      {
        key: 'FAZ',
        icon: { component: FAZNode, delta: { x: '- 65px', y: '' } },
        label: {
          text: 'Advanced analytics powered by FortiAnalyzer',
          style: { width: 210, transform: `translate(calc(-50% - 200px), calc(-50%))` },
        },
      },
    ].map((node, index) => (
      <div key={node.key}>
        <Icon
          component={node.icon.component}
          style={{
            position: 'absolute',
            fontSize: '50px',
            borderRadius: '100%',
            top: '50%',
            left: '50%',
            transform: `translate(calc(-50% ${node.icon.delta.x}), calc(-50% ${node.icon.delta.y}))`,
            zIndex: 1,
            display: index === currentIndex ? 'inline-block' : 'none',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            opacity: index === currentIndex ? 1 : 0.6,
            fontSize: index === currentIndex ? '14px' : '12px',
            fontWeight: 'var(--nu-theme-dimension-bold-font-weight)',
            ...(node.label?.style || {}),
          }}
        >
          {node.label?.text}
        </div>
      </div>
    ));
  }, [currentIndex]);

  return (
    <div
      className="flex-col cross-center"
      style={{
        background: `#ffffff url(${heroImageBg}) center`,
        height: '100%',
        width: '100%',
      }}
      data-testid="onboarding-hero-image"
    >
      <h2 className="flex-row main-center" style={{ paddingTop: 60, fontSize: '24px' }}>
        {title}
      </h2>

      <div className="flex-item" style={{ position: 'relative' }}>
        {nodes}

        <img
          src={heroImageHexagon}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(calc(-50% - 6px), -50%)',
          }}
        />
      </div>
    </div>
  );
};

HeroImage.propTypes = {
  title: PropTypes.string,
};

export default HeroImage;
