import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Prompt from 'components/Prompt';
import ActionButton from 'pages/Express/shared/ActionButton';
import { logout } from 'utils/util';
import storage from 'utils/storage';

const inputStyle = {
  fontSize: '1.5em',
  height: 48,
  width: '2em',
  textAlign: 'center',
  fontWeight: 500,
};

const ActivateTrial = ({ checkPromotionalCode }) => {
  const [pending, setPending] = useState(false);

  const formRef = useRef();

  const license = storage.get('license');
  const expiry = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(license?.expire));

  useEffect(() => {
    const inputElements = formRef.current?.querySelectorAll('input');
    inputElements?.forEach((ele, index) => {
      ele.addEventListener('keydown', e => {
        // if the keycode is backspace & the current field is empty focus the input before the current.
        // Then the event happens which will clear the "before" input box.
        if (e.keyCode === 8 && e.target.value === '') inputElements[Math.max(0, index - 1)].focus();
      });
      ele.addEventListener('input', e => {
        // take the first character of the input
        const [first, ...rest] = e.target.value?.replace('-', '')?.toUpperCase();
        e.target.value = first ?? ''; // first will be undefined when backspace was entered, so set the input to ""
        const lastInputBox = index === inputElements.length - 1;
        const didInsertContent = first !== undefined;
        if (didInsertContent && !lastInputBox) {
          // continue to input the rest of the string
          inputElements[index + 1].focus();
          inputElements[index + 1].value = rest.join('');
          inputElements[index + 1].dispatchEvent(new Event('input'));
        }
      });
    });
  }, []);

  const handleActivate = useCallback(e => {
    e.preventDefault();
    const inputElements = formRef.current?.querySelectorAll('input');
    const code = [...inputElements]
      ?.map(({ value }, index) => ([3, 7].includes(index) ? `${value}-` : value)) // insert '-' at certain position
      .join('');

    setPending(true);
    return checkPromotionalCode(code).finally(() => setPending(false));
  }, []);

  return (
    <Prompt message="FortiSASE Activation Code Required" showIcon={false}>
      <form ref={formRef} onSubmit={handleActivate} onChange={e => console.log({ e })}>
        <p>
          Enter your activation code below to activate your FortiSASE trial. Your trial expires on{' '}
          {expiry} and includes access to one security region. To access the full suite of features
          or to extend your trial, purchase a production license.
        </p>
        <fieldset>
          <div className="flex-row main-center cross-center" style={{ margin: '24px 0 36px 0' }}>
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
            <span style={{ fontSize: 20, margin: '0 0.2em' }}>-</span>
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
            <span style={{ fontSize: 20, margin: '0 0.2em' }}>-</span>
            <input name="code" style={inputStyle} required />
            <input name="code" style={inputStyle} required />
          </div>
        </fieldset>
        <div className="flex-row main-center cross-center">
          <button
            type="submit"
            className="primary"
            style={{ minWidth: '8em', marginRight: '1em' }}
            onClick={e => e.target.blur()}
            disabled={pending}
          >
            Activate trial
          </button>
          <ActionButton key="abort" onClick={logout}>
            Abort
          </ActionButton>
        </div>
      </form>
    </Prompt>
  );
};

ActivateTrial.propTypes = {};

export default ActivateTrial;
