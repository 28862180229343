import Confirm from 'components/Confirm/Confirm';
import Prompt from 'components/Prompt';
import { get } from 'lodash';
import ProvisionWizard from 'pages/Express/ProvisionWizard';
import ActionButton from 'pages/Express/shared/ActionButton';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import storage from 'utils/storage';

const Renewed = ({ data, onboard, enterSite, ...props }) => {
  const confirmRef = useRef();
  const [pending, setPending] = useState(false);
  const prompt = get(data, 'prompt', {});
  const actions = get(data, 'actions', []);
  const license = storage.get('license');
  const siteSeats = get(license, 'security_entitlement.site.seat', 0);
  const addonSeats = get(license, 'security_entitlement.site.addon_seats', 0);
  const totalSeats = siteSeats + addonSeats;
  const needSelectSites = totalSeats !== get(data, 'security.federated_sites', []).length;

  const onboardHandler = useCallback(
    config => {
      return new Promise((resolve, reject) => {
        confirmRef.current.show({
          onOk() {
            const requestOptions = {
              ...config,
            };
            setPending(true);
            return onboard(requestOptions)
              .then(data => resolve(data))
              .catch(err => reject(err))
              .finally(() => setPending(false));
          },
          onCancel() {
            setPending(false);
            reject();
          },
        });
      });
    },
    [onboard]
  );

  const clickHandler = useCallback(
    ({ key, method, href }) => {
      switch (key) {
        case 'onboard':
          onboardHandler({ method, url: href });
          break;
        case 'enter':
          enterSite();
          break;
        default:
      }
    },
    [enterSite, onboardHandler]
  );

  const actionButtons = useMemo(() => {
    return actions.map(meta => {
      return (
        <ActionButton
          type={meta.type}
          key={meta.key}
          disabled={pending}
          onClick={() => clickHandler(meta)}
        >
          {meta.label}
        </ActionButton>
      );
    });
  }, [actions, clickHandler, pending]);

  return (
    <>
      {needSelectSites ? (
        <ProvisionWizard data={data} onboard={onboardHandler} enterSite={enterSite} />
      ) : (
        <>
          <Prompt {...prompt} {...props} actions={actionButtons} data-testid="renewed-prompt" />
        </>
      )}
      <Confirm
        ref={confirmRef}
        title="Would you like to continue?"
        okText="Continue"
        content={`FortiSASE may experience up to 10 minutes of downtime while the entitlement is applied. If any errors occur, FortiSASE cannot automatically rollback without FortiCare Support.`}
      />
    </>
  );
};

Renewed.propTypes = {
  data: PropTypes.object.isRequired,
  onboard: PropTypes.func.isRequired,
  enterSite: PropTypes.func.isRequired,
};

export default Renewed;
