import api from 'api';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addNotification } from 'redux/GlobalSetting/globalSetting.actions';
import {
  getLogInfo,
  mappingData as mappingLogData,
  updateLogStatus,
} from 'redux/Logs/logs.actions';
import {
  checkTurboSiteSyncStatus,
  getSiteList,
  updateSiteStatus,
} from 'redux/Security/Security.actions';
import { showNotification } from 'redux/Socket/socket.notification';
import { getPath } from 'routeConfig';
import { RESOURCE_STATUS } from 'utils/constant';
import { needQuerySyncStatus } from 'utils/services/site.service';

import useInterval from './useInterval';

const REFRESH_INTERVAL = 15000; // 15 seconds (15000 milliseconds)

const useNotifications = props => {
  const dispatch = useDispatch();
  const breakpoint = useSelector(state => state.globalSetting.breakpoint);
  const isAuthenticated = useSelector(state => state.globalSetting.isAuthenticated);
  const uiMode = useSelector(state => state.globalSetting.tempMode);
  const { pathname } = useLocation();
  const securitySites = useSelector(state => state.security.sites);
  const pendingSites = securitySites.filter(
    site => site.resource_status === RESOURCE_STATUS.PENDING
  );
  const warningSites = securitySites.filter(site => needQuerySyncStatus(site));
  const tenantLogStatusData = useSelector(state => state.logs);

  useEffect(() => {
    isAuthenticated && !!sessionStorage.getItem('access_token') && dispatch(getSiteList());
    isAuthenticated && !!sessionStorage.getItem('access_token') && dispatch(getLogInfo());
  }, [dispatch, isAuthenticated, pathname, uiMode]);

  useInterval(() => {
    if (isAuthenticated && !!sessionStorage.getItem('access_token')) {
      // if there's any turbo site under warning status, trigger polling
      warningSites.forEach(({ id }) => {
        dispatch(checkTurboSiteSyncStatus(id));
      });
      // if there's any site under pending status, trigger polling
      pendingSites.forEach(({ name, id }) => {
        api.getSite(id).then(res => {
          const site = _get(res, 'data.data', {});

          if (_isEmpty(site)) {
            const notificationPaylaod = {
              data: {
                name,
                resource_status: 'terminated', //FIXME: should be provided by backend?
                state_label: 'Terminated', //FIXME: should be provided by backend?
                timestamp: new Date(),
              },
              type: 'security',
            };
            const allowPopup = pathname !== getPath('site-list');
            if (allowPopup) {
              showNotification(notificationPaylaod, {
                style: {
                  width: 420,
                  marginLeft: breakpoint === 'xs' ? 0 : 384 - 420,
                  maxWidth: '96vw',
                },
              });
            }
            dispatch(addNotification(notificationPaylaod));
            dispatch(getSiteList());
            return;
          }

          const target = pendingSites.find(item => item.id === id);
          const statusChanged =
            target.resource_status !== site.resource_status ||
            target.resource_state !== site.resource_state;
          if (statusChanged) {
            // format payload to be compatible with socket notification
            const notificationPaylaod = {
              data: {
                ...site,
                timestamp: site._updated,
              },
              type: 'security',
            };

            const allowPopup =
              pathname !== getPath('site-list') &&
              pathname !== getPath('site-detail', { ...(site.id && { id: site.id }) });
            if (allowPopup) {
              showNotification(notificationPaylaod, {
                style: {
                  width: 420,
                  marginLeft: breakpoint === 'xs' ? 0 : 384 - 420,
                  maxWidth: '96vw',
                },
              });
            }
            dispatch(addNotification(notificationPaylaod));
          }

          dispatch(updateSiteStatus(site));
        });
      });

      // if the tenant level log status is pending, trigger polling
      RESOURCE_STATUS.isPending(tenantLogStatusData.resourceStatus) &&
        api.getLogInfo(res => {
          const data = _get(res, 'data.data', {});
          const logStatusData = mappingLogData(data);
          const statusChanged =
            logStatusData.resourceStatus !== tenantLogStatusData.resourceStatus ||
            logStatusData.resourceState !== tenantLogStatusData.resourceState;

          if (statusChanged) {
            // format payload to be compatible with socket notification
            const notificationPaylaod = {
              data: {
                ...data,
                name: 'Analytics',
                timestamp: data._updated || new Date(),
              },
              type: 'analytics',
            };
            // no need to pop up analytics notification when the user is at the setting page
            const allowPopup = pathname !== getPath('faz-config');
            if (allowPopup) {
              showNotification(notificationPaylaod, {
                style: {
                  width: 420,
                  marginLeft: breakpoint === 'xs' ? 0 : 384 - 420,
                  maxWidth: '96vw',
                },
              });
            }
            dispatch(addNotification(notificationPaylaod));
          }

          dispatch(updateLogStatus(logStatusData));
        });
    } else {
      console.warn('Token expired');
    }
  }, REFRESH_INTERVAL);
};

useNotifications.propTypes = {};

export default useNotifications;
