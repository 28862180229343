import './PreLaunch.module.less';

import * as theming from '@neutrino/theming';
import { camelCase } from 'lodash';
import ActionButton from 'pages/Express/shared/ActionButton';
import ProvisionPrompt from 'pages/Express/shared/ProvisionPrompt';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

const themeName = 'CloudAppLight';
const themeObj = theming[`${camelCase(themeName)}Theme`];

const Running = ({ data, launchSites, ...props }) => {
  const prompt = data?.prompt || {};
  const actions = data?.actions || [];

  const clickHandler = useCallback(({ method, key, href }) => {
    switch (key) {
      case 'documentation':
        window.open(href, '_blank');
        break;
      case 'launch':
        launchSites({ method, url: href });
        break;
      default:
    }
  }, []);

  const actionButtons = useMemo(() => {
    return actions.map(meta => {
      switch (meta.key) {
        case 'support': {
          return (
            <SupportButton key={meta.key} link={meta.href} ticket={meta.ticket} type={meta.type} />
          );
        }
        default:
          return (
            <ActionButton
              type={meta.type}
              key={meta.key}
              onClick={() => clickHandler(meta)}
              styleName="pre-launch-buttons"
            >
              {meta.label}
            </ActionButton>
          );
      }
    });
  }, []);

  return (
    <ProvisionPrompt
      {...prompt}
      {...props}
      actions={actionButtons}
      data-testid="pre-launch-prompt"
    />
  );
};

Running.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Running;
