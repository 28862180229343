import './SideBar.module.less';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import { Badge, Layout, Menu, Popover, Tooltip } from 'antd';
import fgcLogo from 'assets/images/FGC-logo-red.png';
import clsx from 'clsx';
import NotificationContent from 'components/NotificationContent';
import en from 'Languages/en.json';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _keys from 'lodash/keys';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  getMaintInfo,
  getVersion,
  toggleSiderCollapse,
} from 'redux/GlobalSetting/globalSetting.actions';
import { getLogInfo } from 'redux/Logs/logs.actions';
import { connectSocket } from 'redux/Socket/socket.actions';
import routes, { getPath } from 'routeConfig';
import { RESOURCE_STATUS } from 'utils/constant';
import useNotifications from 'utils/hooks/useNotifications';
import usePermissions from 'utils/hooks/usePermissions';
import useUserRole from 'utils/hooks/useUserRole';
import { copyStringToClipboard, isMobileDevice, logout } from 'utils/util';

const { Sider } = Layout;
const { SubMenu, ItemGroup } = Menu;
const collapsedWidth = 60;

const SideBar = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const role = useUserRole();
  const permissions = usePermissions(role);
  const uiMode = useSelector(state => state.globalSetting.tempMode);

  const theme = useSelector(state => state.globalSetting.theme);
  const isAuthenticated = useSelector(state => state.globalSetting.isAuthenticated);
  const resourceStatus = useSelector(state => state.logs.resourceStatus);
  const siderCollapsed = useSelector(state => state.globalSetting.siderCollapsed);
  const version = useSelector(state => state.globalSetting.version);
  const tenantUser = useSelector(state => state.globalSetting.user);
  const fortiOneAccounts = useSelector(state => state.globalSetting.fortiOneAccounts);
  const sysInfo = useSelector(state => state.globalSetting.sysInfo);
  const notifications = useSelector(state => state.globalSetting.notifications);

  const [latestOpenKey, setLatestOpenKey] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [isCopying, setIsCopying] = useState(false);
  const [notiVisible, setNotiVisible] = useState(false);

  useNotifications();

  const brandTip = useMemo(
    () => (
      <div>
        <div>
          <strong>{en.SYSTEM.PRODUCT_NAME}</strong>
        </div>
        <div style={{ fontSize: 10 }}>Software Defined Security Edge</div>
      </div>
    ),
    []
  );

  const menuItemConfig = useMemo(
    () =>
      [
        {
          label: en.TERMS.SECURITY,
          id: 'security',
          icon: 'safety',
          url: getPath('site-list'),
        },
        {
          label: en.TERMS.ANALYTICS,
          id: 'analytics',
          icon: 'dashboard',
          url: getPath('analytics'),
        },
        {
          label: en.TERMS.SETTING_PLURAL,
          id: 'settings',
          icon: 'setting',
          children: [
            {
              label: en.TERMS.USER_PLURAL,
              id: 'users',
              url: getPath('users'),
            },
            {
              label: en.TERMS.ANALYTICS,
              id: 'setting-analytics',
              url: getPath('faz-config'),
            },
            {
              label: en.TERMS.ACCESS_TOKENS,
              id: 'access_tokens',
              url: getPath('access-tokens'),
            },
          ],
        },
      ].filter(item => !!item),
    []
  );
  const rootSubmenuKeys = useMemo(() => menuItemConfig.map(item => `submenu-${item.id}`), [
    menuItemConfig,
  ]);

  const getMenuConfig = useCallback(
    target => {
      const defaultOpenKeys = [];
      const defaultSelectedKeys = [];
      const match = routes.reduce((result, route) => {
        if (result) return result;
        return matchPath(target || location.pathname, route);
      }, null);
      let pathname;
      if (!match) {
        pathname = target || location.pathname;
      } else if (!_isEmpty(match.params)) {
        // remove all params before mathing with menu items
        pathname = _keys(match.params).reduce((pathname, param) => {
          return pathname.replace(`/:${param}`, '');
        }, match.path);
      } else {
        pathname = match.path;
      }
      menuItemConfig.find(item => {
        if (item.members) {
          return item.members.some(el => {
            const match = el.url === pathname;
            if (match) {
              defaultSelectedKeys.push(`item-group-${el.id}`);
            }
            return match;
          });
        } else if (item.children) {
          return item.children.some(el => {
            const match = el.url === pathname;

            if (match) {
              if (!siderCollapsed) {
                defaultOpenKeys.push(`submenu-${item.id}`);
              }
              setLatestOpenKey(`submenu-${item.id}`);

              defaultSelectedKeys.push(`submenu-${item.id}`);
              defaultSelectedKeys.push(`item-${el.id}`);
            }
            return match;
          });
        } else {
          const match = _isString(pathname) && pathname.startsWith(item.url);
          if (match) {
            defaultSelectedKeys.push(`item-${item.id}`);
          }
          return match;
        }
      });

      return { defaultOpenKeys, defaultSelectedKeys };
    },
    [location.pathname, menuItemConfig, siderCollapsed]
  );

  const onOpenChange = useCallback(
    keys => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      setLatestOpenKey(latestOpenKey);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(keys);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
      }
    },
    [openKeys, rootSubmenuKeys]
  );

  const isMenuDisabled = useCallback(
    item => {
      switch (item.id) {
        case 'security':
          return !permissions.security.accessible;
        case 'analytics':
          return !RESOURCE_STATUS.isRunning(resourceStatus);
        case 'setting-analytics':
          return !permissions.analytics.accessible;
        case 'automation':
          return !permissions.automation;
        default:
          return false;
      }
    },
    [
      permissions.analytics.accessible,
      permissions.automation,
      permissions.security.accessible,
      resourceStatus,
    ]
  );

  const renderMenuItems = useCallback(
    items =>
      items.map(item => {
        const content = (
          <Fragment>
            {item.icon && <LegacyIcon type={item.icon} />}
            <span>&nbsp;{item.label}</span>
          </Fragment>
        );

        // render menu item group
        if (!_isEmpty(item.members)) {
          return (
            <ItemGroup title={content} key={`item-group-${item.id}`}>
              {renderMenuItems(item.members)}
            </ItemGroup>
          );
        }

        // render submenu
        if (!_isEmpty(item.children)) {
          return (
            <SubMenu title={content} key={`submenu-${item.id}`}>
              {renderMenuItems(item.children)}
            </SubMenu>
          );
        }

        // render menu item
        return (
          <Menu.Item key={`item-${item.id}`} disabled={isMenuDisabled(item)}>
            {item.url ? <Link to={item.url}>{content}</Link> : content}
          </Menu.Item>
        );
      }),
    [isMenuDisabled]
  );

  const menuItems = renderMenuItems(menuItemConfig);

  useEffect(() => {
    const defaultMenuConfig = getMenuConfig();

    setOpenKeys(_get(defaultMenuConfig, 'defaultOpenKeys', []));
    setSelectedKeys(_get(defaultMenuConfig, 'defaultSelectKeys', []));

    isAuthenticated && dispatch(getVersion());
    // isAuthenticated && dispatch(getLogInfo());
    // isAuthenticated && dispatch(getMaintInfo());
    // dispatch(connectSocket());
  }, [dispatch, getMenuConfig, isAuthenticated]);

  useEffect(() => {
    const defaultMenuConfig = getMenuConfig(location.pathname);
    setOpenKeys(_get(defaultMenuConfig, 'defaultOpenKeys', []));
    setSelectedKeys(_get(defaultMenuConfig, 'defaultSelectedKeys', []));
  }, [getMenuConfig, location.pathname]);

  const copyVersion = useCallback(() => {
    setIsCopying(true);
    copyStringToClipboard(version);
    setTimeout(() => setIsCopying(false), 1200);
  }, [version]);

  const switchAccount = useCallback(() => {
    const ssoLoginPath = getPath('sso-login');
    history.push(ssoLoginPath);
  }, [history]);

  const onCollapse = useCallback(
    collapsed => {
      const keys = collapsed ? [] : [latestOpenKey];
      setOpenKeys(keys);
      dispatch(toggleSiderCollapse(collapsed));
    },
    [dispatch, latestOpenKey]
  );

  const menuCollapsedStyle = siderCollapsed ? { width: collapsedWidth } : {};
  const isMobile = isMobileDevice();
  const siderProps = isMobile ? { trigger: null } : {};
  const isSSOUser = tenantUser.auth_type === 'sso';
  const moreThanOneActs = fortiOneAccounts.length > 1;

  return (
    <Sider
      theme={theme}
      collapsible
      collapsed={siderCollapsed}
      onCollapse={onCollapse}
      collapsedWidth={collapsedWidth}
      styleName={clsx('sidebar', { 'mobile-sidebar': isMobile && siderCollapsed })}
      {...siderProps}
    >
      <div styleName={clsx('logo-mark', { 'left-aligned': !siderCollapsed })}>
        <Tooltip placement="right" title={brandTip}>
          <img src={fgcLogo} style={{ height: 32 }} alt="logo" />
        </Tooltip>
        {!siderCollapsed && <strong> {en.SYSTEM.PRODUCT_NAME} </strong>}
      </div>

      <div className="flex-col space-between" styleName="sider-ctn">
        <section styleName="menu-ctn">
          <Menu
            defaultSelectedKeys={['1']}
            selectedKeys={selectedKeys}
            mode="inline"
            theme={theme}
            style={menuCollapsedStyle}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            onSelect={params => setSelectedKeys(params.selectedKeys)}
          >
            {menuItems}
          </Menu>
        </section>

        {
          // comment this code to support multiple accounts
          // isSSOUser && moreThanOneActs && (
          //   <section styleName="down-sider-ctn" onClick={switchAccount}>
          //     <span styleName="menu-span">
          //       <Tooltip placement="right" title="Switch User">
          //         <TeamOutlined />
          //       </Tooltip>
          //       <span className={clsx({ hide: siderCollapsed })} styleName="menu-btn-text">
          //         Switch User
          //       </span>
          //     </span>
          //   </section>
          // )
        }

        <section styleName="down-sider-ctn">
          <span styleName={sysInfo.length ? 'menu-span maint-alert' : 'menu-span'}>
            <Popover
              overlayClassName={theme}
              onVisibleChange={visible => setNotiVisible(visible)}
              placement="topRight"
              trigger="click"
              content={<NotificationContent visible={notiVisible} />}
            >
              <Badge count={sysInfo.length || notifications.filter(item => !item.read).length} dot>
                <NotificationOutlined
                  style={{ color: 'rgb(var(--nu-theme-on-color-background))' }}
                />
              </Badge>
              <span className={clsx({ hide: siderCollapsed })} styleName="menu-btn-text">
                Notification
              </span>
            </Popover>
          </span>
        </section>

        <section styleName="down-sider-ctn" onClick={logout}>
          <span styleName="menu-span">
            <Tooltip placement="right" title="Logout">
              <PoweroffOutlined />
            </Tooltip>
            <span className={clsx({ hide: siderCollapsed })} styleName="menu-btn-text">
              {en.LOGIN.LOGOUT}
            </span>
          </span>
        </section>

        {version && (
          <section styleName="down-sider-ctn">
            <span styleName="menu-span" className="ellipsis" onClick={copyVersion}>
              <Tooltip placement="right" title={isCopying ? 'Copied' : version}>
                {!isCopying && <InfoCircleOutlined />}
                {isCopying && <CheckCircleOutlined style={{ color: '#52c41a' }} />}
              </Tooltip>
              <span className={clsx({ hide: siderCollapsed })} styleName="menu-btn-text">
                <Tooltip placement="top" title={isCopying ? 'Copied' : 'Copy'}>
                  {version}
                </Tooltip>
              </span>
            </span>
          </section>
        )}
      </div>
    </Sider>
  );
};

SideBar.propTypes = {};

export default SideBar;
