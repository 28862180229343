import './App.less';

import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { setActiveTheme } from 'utils/util';

import AppLayout from './AppLayout';
import history from './history';
import store from './store';

setActiveTheme('dark');

function App() {
  return (
    <Provider store={store}>
      <div id="App">
        <Router history={history}>
          <AppLayout />
        </Router>
      </div>
    </Provider>
  );
}

export default App;
