import './Header.module.less';

import { caretDownIcon, userCircleIcon } from '@neutrino/fa-icons/solid';
import { Dropdown, Menu, Typography } from 'antd';
import NuIcon from 'components/NuIcon';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCookie } from 'utils/util';

import HeaderService from './header.service';

const AccountDropdown = ({ hKey }) => {
  const location = useLocation();
  const portals = useSelector(state => state.globalSetting.portals);
  const tenantUser = useSelector(state => state.globalSetting.user);
  const isSSOPath = location.pathname === '/sso-login';
  const dropdownRef = useRef();
  const [dropdownWidth, setDropdownWidth] = useState(50);

  useLayoutEffect(() => {
    setDropdownWidth(dropdownRef.current?.offsetWidth);
  }, []);

  // get account principle at each render loop
  const getSSOUserPrincipal = useCallback(() => {
    const accessToken = sessionStorage.getItem('access_token');

    if (!accessToken) return '';
    try {
      return jwtDecode(accessToken)?.user.principal;
    } catch (error) {
      return '';
    }
  }, []);

  const accountDropdown = useMemo(() => {
    const userMenuItems = (portals?.apps || {}).user_menu_items || [];

    // for standalone user
    if (tenantUser.auth_type !== 'sso' && !isSSOPath) {
      const app = {
        item_id: 10225,
        display_name: 'Logout',
        description: 'Logout from All Applications',
        url: 'https://support-dev.corp.fortinet.com/Credentials/Login/CommonLogout.aspx',
        order: 9,
        image_content:
          'PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMiAzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzIgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHRpdGxlPmNoZWNrRW50PC90aXRsZT4KPGc+Cgk8cGF0aCBkPSJNMjMuMiw3LjRjLTAuOC0wLjYtMS45LTAuNC0yLjUsMC40Yy0wLjYsMC44LTAuNCwxLjksMC40LDIuNWMyLjMsMS43LDMuNiw0LjMsMy42LDcuMWMwLDQuOS00LDguOC04LjgsOC44CgkJYy00LjksMC04LjgtNC04LjgtOC44YzAtMi44LDEuNC01LjUsMy42LTcuMWMwLjgtMC42LDEtMS43LDAuNC0yLjVjLTAuNi0wLjgtMS43LTEtMi41LTAuNGMtMy4yLDIuMy01LjEsNi4xLTUuMSwxMAoJCWMwLDYuOSw1LjYsMTIuNSwxMi41LDEyLjVjNi45LDAsMTIuNS01LjYsMTIuNS0xMi41QzI4LjMsMTMuNSwyNi40LDkuOCwyMy4yLDcuNCIvPgoJPHBhdGggZD0iTTE1LjksMTUuMmMxLDAsMS45LTAuOCwxLjktMS45VjMuN2MwLTEtMC44LTEuOS0xLjktMS45Yy0xLDAtMS45LDAuOC0xLjksMS45djkuNkMxNCwxNC40LDE0LjksMTUuMiwxNS45LDE1LjIiLz4KPC9nPgo8L3N2Zz4K',
      };
      return (
        <Menu>
          <Menu.Item onClick={() => HeaderService.routeToApp(app)}>
            <span className="flex-row cross-center">
              <img
                style={{ width: 20, height: 20, marginRight: '1em' }}
                src={`data:image/svg+xml;base64, ${app?.image_content}`}
                alt="icon"
              />
              <Typography.Text ellipsis>{app?.display_name}</Typography.Text>
            </span>
          </Menu.Item>
        </Menu>
      );
    }

    // for SSO user
    return (
      <Menu>
        {userMenuItems
          // filter out unavailable menu items
          .filter(({ item_id }) => {
            const unavailableAppId = [10222, 10208];
            return !unavailableAppId.includes(item_id);
          })
          .map(app => (
            <Menu.Item key={app?.item_id} onClick={() => HeaderService.routeToApp(app, hKey)}>
              <span className="flex-row cross-center">
                <img
                  style={{ width: 20, height: 20, marginRight: '1em' }}
                  src={`data:image/svg+xml;base64, ${app?.image_content}`}
                  alt="icon"
                />
                <Typography.Text ellipsis style={{ color: 'rgb(30,30,30)' }}>
                  {app?.display_name}
                </Typography.Text>
              </span>
            </Menu.Item>
          ))}
      </Menu>
    );
  }, [hKey, isSSOPath, portals?.apps, tenantUser.auth_type]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={accountDropdown}
      trigger="click"
      overlayStyle={{
        width: dropdownWidth - 1,
        maxWidth: 400,
        maxHeight: '80vh',
      }}
      overlayClassName="account-dropdown nu-pop-up-menu-content"
    >
      <section
        styleName="account-dropdown"
        className="flex-row main-end cross-center"
        ref={dropdownRef}
        data-testid="account-dropdown"
      >
        <NuIcon icon={userCircleIcon} style={{ width: 24, height: 24 }} />
        <span className="ellipsis" styleName="account">
          {getSSOUserPrincipal() || tenantUser.principal}
        </span>
        <NuIcon icon={caretDownIcon} style={{ transform: 'scale(.75)', minWidth: 18 * 0.75 }} />
      </section>
    </Dropdown>
  );
};

AccountDropdown.propTypes = {
  hKey: PropTypes.object,
};

export default AccountDropdown;
