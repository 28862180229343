import './Header.module.less';

import { caretDownIcon } from '@neutrino/fa-icons/solid';
import { Col, Divider, Dropdown, Row } from 'antd';
import NuIcon from 'components/NuIcon';
import SvgIcon from 'components/SvgIcon';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import HeaderService from './header.service';

const SupportDropdown = ({ hKey }) => {
  const portals = useSelector(state => state.globalSetting.portals);

  const renderMenuItem = useCallback(
    item => {
      return (
        <div
          title={item.description}
          styleName="app-item"
          className="flex-row cross-center"
          onClick={() => HeaderService.routeToApp(item, hKey)}
        >
          <img
            style={{ width: 20, height: 20, marginRight: '1em' }}
            src={`data:image/svg+xml;base64, ${item?.image_content}`}
            alt="icon"
          />
          <span>{item.label}</span>
        </div>
      );
    },
    [hKey]
  );

  const servicesDropdown = useMemo(() => {
    const portalMenuHeaders = (portals?.apps || {}).support_menu_headers || [];
    const portalMenuItems = (portals?.apps || {}).support_menu_items || [];

    function formatItems(items) {
      return items.map(({ display_name, item_id }) => {
        const children = portalMenuItems
          .filter(({ section_header }) => section_header === display_name)
          .map(item => ({ label: item.display_name, key: item.item_id, ...item }));
        return {
          type: 'group',
          label: display_name,
          key: item_id,
          children,
        };
      });
    }

    const leftColumnItems = formatItems([...portalMenuHeaders].splice(0, 2));
    const rightColumnItems = formatItems([...portalMenuHeaders].splice(2));

    // for SSO user
    return (
      <div className="flex-row" styleName="dropdown-panel">
        <section style={{ width: 190 }}>
          {leftColumnItems.map(section_item => (
            <section key={section_item.key}>
              <div styleName="section-header">{section_item.label}</div>
              <Row>
                {section_item.children.map(item => (
                  <Col key={item.key} span={24}>
                    {renderMenuItem(item)}
                  </Col>
                ))}
              </Row>
            </section>
          ))}
        </section>
        <Divider type="vertical" styleName="divider" />
        <section style={{ width: 320 }}>
          {rightColumnItems.map(section_item => (
            <section key={section_item.key}>
              <div styleName="section-header">{section_item.label}</div>
              <Row>
                {section_item.children.map(item => (
                  <Col key={item.key} span={12}>
                    {renderMenuItem(item)}
                  </Col>
                ))}
              </Row>
            </section>
          ))}
        </section>
      </div>
    );
  }, [portals?.apps, renderMenuItem]);

  return (
    <Dropdown
      placement="bottomRight"
      overlay={servicesDropdown}
      trigger="click"
      overlayStyle={{
        maxWidth: 800,
        maxHeight: '80vh',
      }}
      overlayClassName="support-dropdown nu-pop-up-menu-content"
    >
      <section
        styleName="support-dropdown"
        className="flex-row main-end cross-center"
        data-testid="support-dropdown"
      >
        <SvgIcon type="forticloud-support" styleName="dropdown-icon" />
        <span className="ellipsis" styleName="account">
          Support
        </span>
        <NuIcon icon={caretDownIcon} style={{ transform: 'scale(.75)', minWidth: 18 * 0.75 }} />
      </section>
    </Dropdown>
  );
};

SupportDropdown.propTypes = {
  hKey: PropTypes.object,
};

export default SupportDropdown;
