import './NavBar.module.less';

import { CloseOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Breadcrumbs from 'components/Breadcrumbs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toggleSiderCollapse } from 'redux/GlobalSetting/globalSetting.actions';
import { isMobileDevice } from 'utils/util';

const mapStateToProps = state => ({
  breakpoint: state.globalSetting.breakpoint,
  siderCollapsed: state.globalSetting.siderCollapsed,
});

const mapDispatchToProps = dispatch => ({
  toggleSiderCollapse: siderCollapsed => dispatch(toggleSiderCollapse(siderCollapsed)),
});

class NavBar extends Component {
  render() {
    const { breakpoint, siderCollapsed } = this.props;
    let mobileLayout = false;
    switch (breakpoint) {
      case 'xs':
        mobileLayout = true;
        break;
      case 'xxl':
      case 'xl':
      case 'lg':
      case 'md':
      case 'sm':
      default:
        mobileLayout = false;
    }
    const isMobile = isMobileDevice();
    return (
      <div styleName="navbar" className={'flex-row space-between'}>
        <div className="flex-row cross-center">
          {isMobile && (
            <span data-test="sider-toggle" style={{ marginRight: 16 }}>
              {siderCollapsed && (
                <MenuFoldOutlined
                  style={{ marginRight: 6 }}
                  onClick={() => this.props.toggleSiderCollapse(false)}
                />
              )}
              {!siderCollapsed && (
                <CloseOutlined
                  style={{ marginRight: 6 }}
                  onClick={() => this.props.toggleSiderCollapse(true)}
                />
              )}
            </span>
          )}

          <Breadcrumbs />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
