import Prompt from 'components/Prompt';
import * as get from 'lodash/get';
import ActionButton from 'pages/Express/shared/ActionButton/ActionButton';
import SupportButton from 'pages/Express/shared/SupportButton';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

const Expired = ({ data, ...props }) => {
  const prompt = get(data, 'prompt', {});
  const actions = get(data, 'actions', []);

  const clickHandler = useCallback(({ key, href }) => {
    switch (key) {
      case 'purchase-entitlement':
        window.open(href, '_blank');
        break;
      default:
    }
  }, []);

  const actionButtons = useMemo(() => {
    return actions.map(meta => {
      switch (meta.key) {
        case 'support': {
          return (
            <SupportButton key={meta.key} link={meta.href} ticket={meta.ticket} type={meta.type} />
          );
        }
        default:
          return (
            <ActionButton type={meta.type} key={meta.key} onClick={() => clickHandler(meta)}>
              {meta.label}
            </ActionButton>
          );
      }
    });
  }, []);

  return (
    <>
      <Prompt {...prompt} {...props} actions={actionButtons} data-testid="expired-prompt" />
    </>
  );
};

Expired.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Expired;
