import './ActionButton.module.less';

import PropTypes from 'prop-types';
import React from 'react';

const ActionButton = ({ children, type, className, styleName, style, ...props }) => {
  const classNames = (className || '').split(' ');
  switch (type) {
    case 'primary':
      classNames.push('primary');
      break;
    case 'danger':
      classNames.push('danger');
      break;
    default:
      classNames.push('secondary');
  }
  const styleNames = (styleName || '').split(' ');
  styleNames.push('action-button');
  return (
    <button
      className={classNames.join(' ')}
      styleName={styleNames.join(' ')}
      style={{ minWidth: '8em', ...style }}
      {...props}
    >
      {children}
    </button>
  );
};

ActionButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ActionButton;
