import './Express.module.less';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { axios, fetchWrap, refreshToken } from 'utils/fetch';
import useHKey from 'utils/hooks/useHKey';
import storage from 'utils/storage';

import { SASE_UI_REDIRECT_ROUTE_KEY, setActiveTheme } from '../../utils/util';
import Conflict from './ProvisionStatus/Conflict';
import Expired from './ProvisionStatus/Expired';
import Failed from './ProvisionStatus/Failed';
import Licensed from './ProvisionStatus/Licensed';
import Pending from './ProvisionStatus/Pending';
import PreLaunch from './ProvisionStatus/PreLaunch';
import Renewed from './ProvisionStatus/Renewed';
import Unlicensed from './ProvisionStatus/Unlicensed';

export const POLLING_INTERVAL = 5 * 1000;
const spinner = (
  <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} data-testid="spinner" />
);

const Express = () => {
  const user = useSelector(state => state.globalSetting.user);
  const [status, setStatus] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const { storeHKey } = useHKey();

  useEffect(() => {
    setActiveTheme('dark');
  }, []);

  useEffect(() => {
    const tenant = get(user, 'tenant', {});
    tenant.id &&
      fetchWrap({ url: `/v1/setting/permission/tenants/${tenant.id}/license` })
        .then(({ data }) => storage.set('license', data))
        .catch(err => {
          console.log(err);
        });
  }, [user]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    // get the provision status
    const fetchStatus = () => {
      !status && setLoading(true);
      return fetchWrap({
        url: `/v1/express/start`,
        cancelToken: source.token,
      })
        .then(res => {
          setStatus(get(res, 'data.status', null));
          setData(get(res, 'data.status_details'));
          setLoading(false);
        })
        .catch(err => {
          if (!axios.isCancel(err)) {
            console.log({ err });
            setStatus('failed');
            setData({
              prompt: {
                severity: 'error',
                code: get(err, 'response.data.code') || get(err, 'response.status_code'),
                message: get(err, 'response.data.error.message') || get(err, 'response.statusText'),
              },
            });
            setLoading(false);
          }
        });
    };

    if (status === null) {
      fetchStatus();
      return () => source.cancel(); // cancel promise when component is unmounted
    }

    if (status === 'pending' || status === 'recreating') {
      const interval = setInterval(fetchStatus, POLLING_INTERVAL);
      return function clearTimeout() {
        clearInterval(interval);
        source.cancel();
      };
    }
  }, [status]);

  // to trigger provisioning
  const onboard = useCallback(requestOptions => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    return fetchWrap({
      ...requestOptions,
      cancelToken: source.token,
    })
      .then(res => {
        setStatus(null);
        setData(get(res, 'data.status_details'));
        return Promise.resolve();
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log('onboard error', err);
        }
      });
  }, []);

  const launchSites = useCallback(requestOptions => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setIsRefreshing(true);

    return fetchWrap({
      ...requestOptions,
      cancelToken: source.token,
    })
      .then(res => {
        setStatus(get(res, 'data.status'));
        setData(get(res, 'data.status_details'));
        return new Promise(resolve =>
          refreshToken(() => {
            setIsRefreshing(false);
            resolve();
          })
        );
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          console.log('launch error', err);
        }
      });
  }, []);

  const enterSite = useCallback(() => {
    storeHKey();

    let uiMode = '';
    let queryParams = '';

    const ouData = JSON.parse(sessionStorage.getItem('ou_data') || null);
    // In ou env, already have access token set.
    if (ouData && localStorage.getItem('access_token') && localStorage.getItem('refresh_token')) {
      uiMode = `/${ouData.ouUiMode}`;

      if (
        uiMode?.replace(/^\/|\/$/g, '') === get(data, 'security.ui_mode')?.replace(/^\/|\/$/g, '')
      ) {
        queryParams = `?ouAccountId=${ouData.ouAccountId}`;
      }

      sessionStorage.removeItem('ou_data');
    } else {
      // set for FOS light GUI
      localStorage.setItem('access_token', sessionStorage.getItem('access_token'));
      localStorage.setItem('refresh_token', sessionStorage.getItem('refresh_token'));
    }
    // the backend side will provide the proper uri for accessing the right FOS light ui
    // e.g. /ui-pro, /ui-ga etc.
    uiMode = uiMode || get(data, 'security.ui_mode');
    if (uiMode) {
      const saseUIRoute = sessionStorage.getItem(SASE_UI_REDIRECT_ROUTE_KEY) || '';
      sessionStorage.removeItem(SASE_UI_REDIRECT_ROUTE_KEY);
      window.location.href = `${uiMode}/${saseUIRoute}${queryParams}`;
    }
    return null;
  }, [data]);

  const content = useMemo(() => {
    let organization_access = false;
    if (user.org_data) {
      organization_access = user.org_data.aggregate_access || false;
    }

    // If the user has the organization permission, then skip the provision part and
    // redirect them to the GUI to access the organization portal.
    if (organization_access) {
      if (!isRefreshing) {
        return enterSite();
      }
    } else {
      switch (status) {
        case 'unlicensed': {
          return <Unlicensed data={data} />;
        }
        case 'licensed': {
          return <Licensed data={data} onboard={onboard} />;
        }
        case 'renewed': {
          return <Renewed data={data} onboard={onboard} enterSite={enterSite} />;
        }
        case 'recreating':
        case 'pending': {
          const prompt = get(data, 'prompt');
          return <Pending data={{ prompt, percentage: get(data, 'security.percentage', 0) }} />;
        }
        case 'pre-launch':
          return <PreLaunch data={data} launchSites={launchSites} />;
        case 'running':
          if (!isRefreshing) {
            return enterSite();
          }
        case 'ems-conflict':
        case 'failed': {
          return <Failed data={data} />;
        }
        case 'conflict': {
          return <Conflict data={data} />;
        }
        case 'expired': {
          return <Expired data={data} />;
        }

        default:
      }
    }
  }, [data, enterSite, launchSites, onboard, status, isRefreshing]);

  return (
    <div
      styleName="express"
      className="flex-item flex-col main-center cross-center"
      data-testid="provision"
    >
      {loading || isRefreshing ? (
        spinner
      ) : (
        <div data-testid="content" style={{ maxWidth: '95%', maxHeight: '96%', overflow: 'auto' }}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Express;
