import { postForm } from 'utils/fetch';
import { logout } from 'utils/util';

export default {
  routeToApp(app, hKey) {
    const appName = app?.display_name || app?.app_name;

    switch (appName) {
      case 'Logout':
        logout();
        break;
      case 'Switch Accounts':
        location.href = '/sso-login';
        break;
      default:
        let accessed_app = (hKey?.visited_sites || []).find(site => site.app_name === app.app_name);
        postForm(accessed_app ? accessed_app?.last_url : app.url, { h_key: JSON.stringify(hKey) });
    }
  },
};
