import {
  checkCircleIcon,
  exclamationCircleIcon,
  exclamationTriangleIcon,
  infoCircleIcon,
} from '@neutrino/fa-icons/solid';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import NuIcon from '../NuIcon';

const Alert = ({ message, ...props }) => {
  let type = '';
  switch (props.type) {
    case 'success':
    case 'warning':
    case 'error':
      type = props.type;
      break;
    default:
      type = 'info';
  }

  const icon = useMemo(() => {
    let iconAlias = null;
    switch (type) {
      case 'success':
        iconAlias = checkCircleIcon;
        break;
      case 'warning':
        iconAlias = exclamationTriangleIcon;
        break;
      case 'error':
        iconAlias = exclamationCircleIcon;
        break;
      default:
        iconAlias = infoCircleIcon;
    }

    return (
      <NuIcon
        icon={iconAlias}
        style={{
          width: 18,
          height: 18,
          marginRight: '1em',
        }}
        data-testid={`${type}-icon`}
      />
    );
  }, [type]);

  return (
    <div className={`nu-${type}-message`} data-testid="alert" style={props.style}>
      {icon}
      <div className="message-content">{message}</div>
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  message: PropTypes.node,
  style: PropTypes.object,
};

export default Alert;
