import './Header.module.less';

import saseLogo from 'assets/images/sase_logo.svg';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCookie } from 'utils/util';

import AccountDropdown from './AccountDropdown';
import ServicesDropdown from './ServicesDropdown';
import SupportDropdown from './SupportDropdown';

const PRODUCT_NAME = 'FortiSASE';

const Header = props => {
  const location = useLocation();
  const isAuthenticated = useSelector(state => state.globalSetting.isAuthenticated);
  const tenantUser = useSelector(state => state.globalSetting.user);
  const isSSOPath = location.pathname === '/sso-login';

  const getVisitedSites = useCallback(() => {
    const currSite = {
      app_name: PRODUCT_NAME,
      last_url: window.location.protocol + '//' + window.location.host + '/saml/login',
    };
    try {
      const h_key = JSON.parse(getCookie('h_key'));
      const visited_sites = h_key?.visited_sites;
      if (!visited_sites || visited_sites.length === 0) {
        return [currSite];
      } else {
        const currSiteIndex = visited_sites.findIndex(site => site.app_name === PRODUCT_NAME);
        currSiteIndex > -1
          ? (visited_sites[currSiteIndex].last_url = currSite.last_url)
          : visited_sites.push(currSite);
        return visited_sites;
      }
    } catch (e) {
      console.log('empty h_key');
      return [currSite];
    }
  }, []);

  const hKey = useMemo(() => {
    let newHkey = {
      source_app: PRODUCT_NAME,
      account_id: tenantUser?.tenant?.account_id,
      user_id: tenantUser?.third_party_data?.user_id,
      user_fullaccess: tenantUser?.third_party_data?.user_fullaccess,
      visited_sites: getVisitedSites(),
    };
    try {
      const h_key = JSON.parse(getCookie('h_key'));
      newHkey = {
        ...newHkey,
        account_id: h_key?.account_id,
        user_id: h_key?.user_id,
        user_fullaccess: h_key?.user_fullaccess,
      };
    } catch (e) {
      console.log('empty h_key');
    }
    return newHkey;
  }, [getVisitedSites, tenantUser]);

  return (
    <div styleName="header" className="flex-row space-between cross-center">
      <section className="flex-row">
        <div styleName="logo" className="flex-row cross-center">
          <img src={saseLogo} alt="logo" />
          <strong>FortiSASE</strong>
        </div>

        {(isAuthenticated || isSSOPath) && (
          <section className="flex-row">
            <ServicesDropdown />
            <SupportDropdown />
          </section>
        )}
      </section>

      {(isAuthenticated || isSSOPath) && <AccountDropdown hKey={hKey} />}
    </div>
  );
};

Header.propTypes = {};

export default Header;
