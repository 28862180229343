import './Slide.module.less';

import { timesIcon } from '@neutrino/fa-icons/solid';
import { Drawer } from 'antd';
import NuIcon from 'components/NuIcon/NuIcon';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';

const Slide = forwardRef(
  ({ title, children, styleName = '', cancelText, footer, ...props }, ref) => {
    const [visible, setVisible] = useState(false);

    const styleNames = styleName.split(' ');
    styleNames.unshift('slide');

    useImperativeHandle(ref, () => ({
      open() {
        setVisible(true);
      },
    }));

    const slideTitle = useMemo(() => {
      return (
        <div styleName="title">
          <h2>{title}</h2>
        </div>
      );
    }, [title]);

    const closeIcon = (
      <NuIcon
        icon={timesIcon}
        style={{
          color: 'rgb(var(--nu-theme-override-button-text,var(--nu-theme-on-color-background)))',
        }}
      />
    );

    const handleClose = useCallback(
      e => {
        e.target.blur();
        !!props.onClose && props.onClose();
        setVisible(false);
      },
      [props]
    );

    const cancelBtn = useMemo(
      () => (
        <button onClick={handleClose} key="cancel" data-testid="cancel-btn">
          {cancelText || 'Cancel'}
        </button>
      ),
      [cancelText, handleClose]
    );

    const footerBtns = useMemo(() => {
      if (footer) {
        return [cancelBtn, ...footer];
      }
      return false;
    }, [cancelBtn, footer]);

    return (
      <Drawer
        styleName={styleNames.join(' ')}
        visible={visible}
        destroyOnClose
        maskClosable={false}
        title={slideTitle}
        closeIcon={closeIcon}
        onClose={handleClose}
        footer={footerBtns}
        {...props}
      >
        {children}
      </Drawer>
    );
  }
);

Slide.displayName = 'Slide';

Slide.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.array,
  title: PropTypes.string,
  styleName: PropTypes.string,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
};

export default Slide;
