import Item from 'antd/lib/list/Item';
import Alert from 'components/Alert';
import React from 'react';
import { utcToLocal } from 'utils/util';

function MaintenanceBoard(props) {
  const maintenanceInfoToString = data => {
    var res = data.map(item => {
      return (
        <div className={'maintenance-info' + '-' + Item.region_name} key={item.id}>
          {`${item.alias} is under maintenance until ${utcToLocal(item.recovery_time)}.`}
        </div>
      );
    });
    return res;
  };
  var desc = <div> {maintenanceInfoToString(props.data)} </div>;
  return <Alert message={desc} type="warning" style={{ maxWidth: 'calc(800px - 1em)' }} />;
}

export default MaintenanceBoard;
