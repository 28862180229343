import { Modal } from 'antd';
import _get from 'lodash/get';
import Express from 'pages/Express/Express';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { changeTheme, setTempUIMode } from 'redux/GlobalSetting/globalSetting.actions';
import { ADVANCED_MODE, EXPRESS_MODE } from 'utils/constant';
import storage from 'utils/storage';
import { isTokenExpired, logout, SASE_UI_REDIRECT_ROUTE_KEY } from 'utils/util';

import routes, { defaultRoute, getPath, logoutPath } from './routeConfig';

let tokenExpiredModalOpen = false;

const getFallbackRoute = () => {
  const accessToken = sessionStorage.getItem('access_token');

  // exclude logoutPath to prevent infinity loop when token expired
  if (accessToken && location.pathname !== logoutPath) {
    try {
      const tokenExpired = isTokenExpired(accessToken);
      if (tokenExpired) {
        if (!/\/(login|logout|sso-login)$/.test(window.location.pathname)) {
          !tokenExpiredModalOpen &&
            Modal.confirm({
              title: 'Token expired!',
              centered: true,
              okText: 'Logout',
              onOk() {
                logout();
                tokenExpiredModalOpen = false;
              },
              cancelButtonProps: {
                style: { display: 'none' },
              },
            });
          tokenExpiredModalOpen = true;
        }
      }
    } catch (error) {
      console.error('parse token error');
    }
  } else {
    return (
      <Redirect
        to={{
          pathname: getPath('login'),
        }}
      />
    );
  }
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = storage.get('globalSetting') || {};
  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? <Component {...props} /> : getFallbackRoute())}
    />
  );
};

const ExpressRoutes = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={props => {
        const { isAuthenticated } = storage.get('globalSetting') || {};
        const { location } = props;

        const params = new URLSearchParams(location.search);
        const redirectionParameter = params.get('sase-ui-redir');

        if (redirectionParameter) {
          const regex = /\/ui-(ng|pro|ga|special)\//g;
          const saseUIRoute = redirectionParameter.replace(regex, '');
          sessionStorage.setItem(SASE_UI_REDIRECT_ROUTE_KEY, saseUIRoute);
        }

        return isAuthenticated ? <Express /> : getFallbackRoute();
      }}
    />

    {routes
      .filter(route => !!route.path && !!route.component)
      .filter(route => route.public)
      .map(route =>
        route.public ? (
          // a route can be access without authentication only if the public property is set to true
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ) : (
          <PrivateRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        )
      )}

    <Redirect to={'/'} />
  </Switch>
);

const AdvancedRoutes = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={props => {
        const { isAuthenticated } = storage.get('globalSetting') || {};

        return isAuthenticated ? <Redirect to={defaultRoute} /> : getFallbackRoute();
      }}
    />

    <Route
      exact
      path={getPath('automation')}
      render={props => {
        const { isAuthenticated } = storage.get('globalSetting') || {};
        return isAuthenticated ? <Redirect to={getPath('forti-formation')} /> : getFallbackRoute();
      }}
    />

    <Route
      exact
      path={getPath('settings')}
      render={props => {
        const { isAuthenticated } = storage.get('globalSetting') || {};
        return isAuthenticated ? <Redirect to={getPath('users')} /> : getFallbackRoute();
      }}
    />

    {routes
      .filter(route => !!route.path && !!route.component)
      .map(route =>
        route.public ? (
          // a route can be access without authentication only if the public property is set to true
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ) : (
          <PrivateRoute
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        )
      )}

    <Redirect to={defaultRoute} />
  </Switch>
);

const Routes = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { user, tempMode } = useSelector(state => state.globalSetting);
  const licenseModeModifiable = _get(user, 'tenant.company.license_mode_modifiable', false);
  const licenseMode = _get(user, 'license_mode', EXPRESS_MODE);
  const _tempMode = _get(queryString.parse(window.location.search), 'mode');
  if (_tempMode && !tempMode) {
    dispatch(setTempUIMode(_tempMode));
  }

  // set fos version locally
  const fosVersion = _get(queryString.parse(window.location.search), 'fos_version', '');
  if (window.location.pathname === '/') {
    window.sessionStorage.setItem('fos_version', fosVersion);
  }
  // set flag for debugging
  // this flag will be passed to the backend when calling /setting/infra/regions
  const showAllRegions = _get(
    queryString.parse(window.location.search),
    'show_all_regions',
    'false'
  );
  // prevent the param be overwritten after route changes
  if (window.location.pathname === '/') {
    window.sessionStorage.setItem('show_all_regions', showAllRegions);
  }

  let organization_access = false;
  if (user.org_data) {
    organization_access = user.org_data.aggregate_access || false;
  }

  // get ui mode
  // The user with the organization portal access should not be able to view the advanced mode regardless their license status.
  const uiMode = organization_access
    ? EXPRESS_MODE
    : licenseModeModifiable
    ? tempMode || licenseMode
    : licenseMode;

  useEffect(() => {
    if (uiMode === ADVANCED_MODE) {
      dispatch(changeTheme('light'));
    } else {
      dispatch(changeTheme('dark'));
    }
  }, [dispatch, uiMode]);

  return uiMode === ADVANCED_MODE ? <AdvancedRoutes /> : <ExpressRoutes />;
};

export default Routes;
