import './Pending.module.less';

import { Progress } from 'antd';
import ProvisionPrompt from 'pages/Express/shared/ProvisionPrompt';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const Pending = ({ data, ...props }) => {
  const prompt = data.prompt || {};
  const percentage = Number(data.percentage);

  const bannerMessage = useMemo(() => {
    // messages will be rotated based on the percentage
    return (
      <div styleName="banner-message">
        <span style={{ display: percentage < 50 ? 'inline' : 'none' }}>
          Monitor and protect endpoints: halt viruses, ransomware and other threats.
        </span>
        <span style={{ display: percentage >= 50 && percentage < 60 ? 'inline' : 'none' }}>
          FortiSASE includes rich analytics, powered by FortiAnalyzer. You can schedule reports on a
          wide range of topics: Cyber-bullying indicators, cyber threat assessments, and more.
        </span>
        <span style={{ display: percentage >= 60 ? 'inline' : 'none' }}>
          Customize rules to automatically submit executables to FortiSandbox.
        </span>
      </div>
    );
  }, [percentage]);

  return (
    <ProvisionPrompt
      {...prompt}
      message={null}
      description={
        <div style={{ fontWeight: 500, fontSize: 17, color: '#000', textAlign: 'center' }}>
          {prompt?.description}
        </div>
      }
      heroImage={bannerMessage}
      data-testid="pending-prompt"
      {...props}
    >
      <Progress styleName="progress-bar" percent={percentage} status="active" />
    </ProvisionPrompt>
  );
};

Pending.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pending;
